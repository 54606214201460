import { LoginView} from './Pages'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
    return (
    <>
        <ToastContainer />
        <LoginView />;
    </>);
}

export default App;