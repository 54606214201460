import React from 'react'
import { OptionComponent, PreviewComponent } from '../components'

import Form from 'react-bootstrap/Form'
import { getError } from '../Utils/ErrorUtil'
import {maxNameLenght} from '../Utils/DataUtils'
import { useState } from 'react'
import { Container,Row,Col, Button, Alert } from 'react-bootstrap'

const TemplateAddView = ({alberghi, lingue, tipi, templateService, back}) => { 
    const [albergo, setAlbergo] = useState(alberghi[0])
    const [tipo, setTipo] = useState(tipi[0])
    const [lingua, setLingua] = useState(lingue[0])
    const [body, setBody] = useState("")
    const [nome, setNome] = useState("")
    const [oggetto, setOggetto] = useState("")
    const [text, setText] = useState("")

    const [show, setShow] = useState(false);
    
    const onAlberghiSelect = (e) => {
        setAlbergo(alberghi[e.target.value])
    }
    const onLingueSelect = (e) => {
        setLingua(lingue[e.target.value])
    }
    const onTipiSelect = (e) => {
        setTipo(tipi[e.target.value])
    }
    const add = async (template) => {
        await templateService.addTemplate(template)
        back();
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if(!body || albergo.id === -1 || !nome) {
            setShow(true)
            return
        }
        const template = {
            corpo: body,
            Lingua: lingua,
            Tipo: tipo,
            Albergo: albergo,
            nome: nome,
            oggetto: oggetto,
            corpoText: text
        }
        add(template)
        setBody('')
        setNome('')
        setOggetto('')
    }


    return (
        <Container fluid>
           {!show  && <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
                <Row>
                    <Col>
                        <Form.Label>Albergo</Form.Label>
                        <Form.Select onChange={onAlberghiSelect} label="Alberghi">
                            {alberghi.map((item, index) => 
                                <OptionComponent key={index} index={index} nome={item.nome} />
                            )}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Lingua</Form.Label>
                        <Form.Select onChange={onLingueSelect} label="Lingue">
                            {lingue.map((item, index) => 
                                <OptionComponent key={index} index={index} nome={item.nome} />
                            )}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select onChange={onTipiSelect} label="Tipi">
                            {tipi.map((item, index) => 
                                <OptionComponent key={index} index={index} nome={item.nome} />
                            )}
                        </Form.Select>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3">
                <Row>
                    <Col>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type='text' onChange={(e) => setNome(e.target.value)} placeholder='Inserisci nome' maxLength={maxNameLenght}></Form.Control>
                    </Col>
                    <Col>
                        <Form.Label>Oggetto</Form.Label>
                        <Form.Control type='text' onChange={(e) => setOggetto(e.target.value)} placeholder='Inserisci oggetto'></Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col><Form.Label>Body HTML</Form.Label>
                    <Form.Control as='textarea' rows={10} placeholder='Aggiungi body HTML' value={body} onChange={(e) => setBody(e.target.value)}/></Col>
                    <Col><Form.Label>Body Text</Form.Label>
                    <Form.Control as='textarea' rows={10} placeholder='Aggiungi body text' value={text} onChange={(e) => setText(e.target.value)}/></Col>
                    
                </Row>
            </Form.Group>
            <div className="d-grid gap-2">
               <Col><Button type='submit' variant="success">Salva</Button></Col>  <Col></Col> <Col></Col>
            </div>
            <PreviewComponent corpo={body}/>
        </Form> }
        {show &&  <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Insert a body</Alert.Heading>
        <p>
         You've to insert a body to save a new template and a correct albergo
        </p>
      </Alert>}
    </Container>
    )
}

export default TemplateAddView
