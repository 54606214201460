import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Container,Row,Col, Button, Alert, Form, Table } from 'react-bootstrap'
import foldericon from '../assets/folder.png';
import binicon from '../assets/bin.png';
import previousicon from '../assets/previous.png';
import {ImageService} from '../Services/ImageService';
import {FolderNode} from '../models/ImageFileSystemNode.js';
import { toastUtility } from '../Utils/toast-utilities'

const ImagesFolders = ({tree, currentDirPath, setCurrentDirPath, setItems}) => {
    const [newFolderName, setNewFolderName] = useState("");
    const [currentFolderNode, setCurrentFolderNode] = useState(tree);

    const imageService = new ImageService()
    
    const goToChildren = (newCurrentDirName) => {
        setCurrentDirPath(currentDirPath + newCurrentDirName + '/'); 
        setCurrentFolderNode(currentFolderNode.subfolders.filter(subfolder => subfolder.dirName == newCurrentDirName)[0]);
        setItems([]);
    }

    const goToParent = () => {
        if(currentFolderNode.dirParent != null){
            setCurrentDirPath(currentDirPath.substring(0, nthLastIndexOf(2, "/", currentDirPath) + 1))
            setCurrentFolderNode(currentFolderNode.dirParent);
            setItems([]);
        }
    }

    const nthLastIndexOf = (nth, ch, string) => {
        if (nth <= 0) return string.length;
        return nthLastIndexOf(--nth, ch, string.substring(0, string.lastIndexOf(ch)));
    }

    const onAddFolder = async () => {
        if(currentFolderNode.subfolders.filter(subfolder => subfolder.dirName == newFolderName).length > 0){
            toastUtility.error("Cartella già esistente!");
            return;
        }
        const res = await imageService.addFolder(newFolderName, currentDirPath);
        if (res === 200){
            setNewFolderName("");
            let newCurrentFolderNode = {... currentFolderNode};
            newCurrentFolderNode.subfolders.push(new FolderNode(newFolderName, currentFolderNode));
            setCurrentFolderNode(newCurrentFolderNode);   
        }
    }

    const deleteFolder = async (dirName) => {
        if(!window.confirm("Sei sicuro di eliminare questa cartella e tutto il suo contenuto?")) return;
        let path = currentDirPath.concat(dirName);
        const res = await imageService.deleteFolder(path);
        if (res === 200){
            let newCurrentFolderNode = {... currentFolderNode};
            // delete the node
            newCurrentFolderNode.subfolders = newCurrentFolderNode.subfolders.filter(subfolder => subfolder.dirName != dirName);
            // delete the node from the other child nodes
            newCurrentFolderNode.subfolders.forEach(subfolder => {
                subfolder.dirParent.subfolders = subfolder.dirParent.subfolders.filter(subfold => subfold.dirName != dirName);
            });
            if(newCurrentFolderNode.dirParent != null){
                // delete the node from the parent node
                let currentFolderInParent = newCurrentFolderNode.dirParent.subfolders.filter(subfolder => subfolder.dirName == newCurrentFolderNode.dirName)[0];
                currentFolderInParent.subfolders = currentFolderInParent.subfolders.filter(subfolder => subfolder.dirName != dirName);
                let parentFolderSubfolders = newCurrentFolderNode.dirParent.subfolders.filter(subfolder => subfolder.dirName != currentFolderInParent.dirName);
                parentFolderSubfolders.push(currentFolderInParent);
                newCurrentFolderNode.dirParent.subfolders = parentFolderSubfolders;
            }
            setCurrentFolderNode(newCurrentFolderNode);
        }
    }

    return (
        <>   
        <Form> 
            <table className="table table-condensed" cellPadding="1" cellSpacing="0">
                <tbody>
                    <tr>
                        <th className="col-2">
                            <Form.Control type='text' onChange={(e) => setNewFolderName(e.target.value)} value={newFolderName} placeholder='Inserisci nome cartella'></Form.Control>
                        </th>
                        <th className="col-2">
                            <button onClick={(e) => {
                                e.preventDefault(); 
                                if(newFolderName != ""){
                                    onAddFolder();
                                }
                                }} className="btn btn-light bg-white border">
                                Crea Cartella
                            </button>
                        </th>
                    </tr>
                </tbody>
            </table>
            
            <h5><img src={previousicon} height={30} onClick = {() => goToParent()}/>{currentDirPath} </h5>
            <h5>{currentFolderNode && currentFolderNode.dirName}</h5>

            <Table striped hover>
                <thead>
                    <tr>
                        <th className="col-1"></th>
                        <th className="col-12">Nome</th>
                        <th className="col-1">Elimina</th>
                    </tr>
                </thead>
                <tbody>
                    {currentFolderNode && currentFolderNode.subfolders && currentFolderNode.subfolders.length > 0 &&
                        currentFolderNode.subfolders.map((subfolder, key) => 
                                    <tr key={key}>
                                        <td onClick={() => goToChildren(subfolder.dirName)}><img src={foldericon} height={30}/></td>
                                        <td onClick={() => goToChildren(subfolder.dirName)}>
                                            <Form.Label style={{"marginLeft": "20px"}}>
                                                {subfolder.dirName}
                                            </Form.Label></td>
                                        <td><img src={binicon} height={30} onClick={() => deleteFolder(subfolder.dirName)}/></td>
                                    </tr> 
                        )
                    }
                </tbody>
            </Table>
        </Form>
        </>    
    )
}


export default ImagesFolders;