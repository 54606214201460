import React from 'react'
import { useState } from 'react'
import {Container, Button  }  from 'react-bootstrap';
import {  FaTrash } from 'react-icons/fa';

const AddImageComponent = ({addImages}) => {
    const [images, setImages] = useState([]);
    const [medias, setMedias] = useState([]);
    const loader = {
        html: <input type="file" id="fileloader" onChangeCapture={ async (e) => await addImage(e)} name="image" accept="image/*" onClick={(e) => resetInput(e)} hidden/>,
        click: () => document.getElementById('fileloader')?.click()
    }
    const addImage = async (e)  => {
        if(!e.target.files[0]) {
            return;
        }
        if(isDuplicateImage(e))
            return;
        const src = URL.createObjectURL(e.target.files[0]);
        var media = <img height={80} width={100} src={src}/>
        setMedias([...medias, media]);
        setImages([...images, e.target.files[0]]);
    }
    const save = async () => {
        await addImages(images);
        setImages([]);
        setMedias([]);
    }
    const isDuplicateImage = (e) => {
        return  images.some(item => item["name"] === e.target.files[0]["name"]) && 
                images.some(item => item["size"] === e.target.files[0]["size"]) && 
                images.some(item => item["lastModified"] === e.target.files[0]["lastModified"]) && 
                images.some(item => item["type"] === e.target.files[0]["type"])
    }
    const resetInput = (e) => {
        e.target.value = null
    }

  return (
    <Container>
        {loader.html}
        <button onClick={(e) => {e.preventDefault(); loader.click()}} className="btn btn-light bg-white border">
            Aggiungi Immagine
        </button>
        <div>
            <ul style={{display: 'inline'}}>{medias.map((img,key) => <li style={{display: 'inline'}} key={key}>{img}</li>)}</ul>
        </div>
        { images.length > 0 && 
        <div>
            <button onClick={(e) => {e.preventDefault(); save()}} className="btn btn-light bg-white border">
                Salva
            </button>
            <Button  variant="link" onClick={(e)=> { e.preventDefault(); if(window.confirm("Sicuro di eliminare le immagini da salvare?")){setMedias([]); setImages([]);}}} ><FaTrash/></Button>
        </div>}
    </Container>
  )
}

export default AddImageComponent