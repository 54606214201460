import 'bootstrap/dist/css/bootstrap.min.css';

import { MenuComponent } from '../components';
import { ElementiView, TemplateView} from './'

import { useState } from 'react'
import { Container, Row, Spinner, Col } from 'react-bootstrap';
import ImagesView from './ImagesView';


function MailCareView() {

  const [Template, setTemplate]  = useState(true)
  const [Lingue, setLingue]  = useState(false)
  const [Alberghi, setAlberghi]  = useState(false)
  const [Tipi, setTipi]  = useState(false)
  const [Immagini, setImmagini]  = useState(false)
  

  return (
    <Container>
      <Row>
        <MenuComponent showTemplate={() => {  if(!Template) { setTemplate(!Template); setLingue(false); setAlberghi(false); setTipi(false); setImmagini(false); } } } 
              showTipi= { () => {  if(!Tipi) { setTipi(!Tipi);  setLingue(false); setAlberghi(false); setTemplate(false); setImmagini(false); }  } } 
              showLingue={ () => {  if(!Lingue) { setLingue(!Lingue);  setTemplate(false); setAlberghi(false); setTipi(false); setImmagini(false); }  } } 
              showAlberghi={ () => {  if(!Alberghi) { setAlberghi(!Alberghi);  setLingue(false); setTemplate(false); setTipi(false); setImmagini(false); }  } }
              showImmagini={ () => {  if(!Immagini) { setAlberghi(false);  setLingue(false); setTemplate(false); setTipi(false); setImmagini(!Immagini); }  } }
        />
      </Row>
      <Row><br/></Row>
      <Row>
      
      { Template &&  <TemplateView  /> }
      {Tipi  && <ElementiView tipo={'tipi'} edit={true}/>}
      {Lingue  && <ElementiView tipo={'lingue'} edit={false}/>}
      {Alberghi  && <ElementiView tipo={'alberghi'} edit={true}/>} 
      {Immagini  && <ImagesView />} 
      </Row>
    </Container>
  );
}

export default MailCareView;