
const headers = {
    'authkey' : 'admin'
}

const headersJSON = {
    'Content-Type': 'application/json',
    'authkey' : 'admin'
}


const address = process.env.REACT_APP_API_ADDRESS+"/api/"
const addressLogin = process.env.REACT_APP_API_ADDRESS+"/login/"

export {headers, headersJSON, address, addressLogin}

