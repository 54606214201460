const errorCodes = {
    noelement: 404,
    dependency: 409,
    general: 500,
    auth: 401,
    validation: 400
  }

  const errorMessages = {
    noelement: "Non esiste l'elemento sul database",
    dependency: "L'elemento non può essere eliminato perchè esiste almeno un template che lo utilizza",
    general: "Errore!",
    auth: "Non sei autorizzato ad eseguire questa operazione",
    validation: "Inserisci i campi in modo corretto"
  }

  const getError = (errorCode) => {
          if(errorCode == errorCodes.noelement) return errorMessages.noelement;
          else if (errorCode == errorCodes.dependency) return errorMessages.dependency;
          else if (errorCode == errorCodes.general) return errorMessages.general;
          else if (errorCode == errorCodes.auth) return errorMessages.auth;
          else if (errorCode == errorCodes.validation) return errorMessages.validation;
          else return "Qualcosa è andato storto error: "+errorCode;
  }

  export {getError}