import React from 'react'
import { OptionComponent, PreviewComponent } from '../components'
import {maxNameLenght} from '../Utils/DataUtils'

import Form from 'react-bootstrap/Form'
import { Container,Row,Col, Button, Alert } from 'react-bootstrap'

import { useState } from 'react'

const TemplateModifyView = ({alberghi, tipi, lingue, template, templateService, back}) => {
    const [body, setBody] = useState(template.corpo)
    const [show, setShow] = useState(false);
    
    const [albergo, setAlbergo] = useState(template.albergo)
    const [tipo, setTipo] = useState(template.tipo)
    const [lingua, setLingua] = useState(template.lingua)

    const [n, setNome] = useState(template.nome)
    const [o, setOggetto] = useState(template.oggetto)
    const [text, setText] = useState(template.corpoText)

    const mod = async (template) => {
        await templateService.modTemplate(template)
        back();
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if(!body || !n ) {
            setShow(true)
            return
        }

        const toUpdate = {
            id: template.id,
            tipo: tipo,
            lingua: lingua,
            albergo: albergo,
            corpo: body,
            oggetto: o,
            nome: n,
            corpoText: text
        }

        mod(toUpdate)
    }


    const getOptionIndex = (elementiList, element) => {
        let index = elementiList.findIndex(x=> x.id === element.id)
        return index;
    }

    return (
        <Container fluid>
            { !show && <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
                <Row>
                    <Col>
                        <Form.Label>Albergo</Form.Label>
                        <Form.Select value={getOptionIndex(alberghi,albergo)} id="SelAlbergo" name="alberghi" onChange={async (e) => setAlbergo(alberghi[e.target.value])}> 
                            {alberghi.map((item, index) => (
                                <OptionComponent key={index} index={index} nome={item.nome}/>
                            ))} 
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Lingua</Form.Label>
                        <Form.Select value={getOptionIndex(lingue,lingua)} id="SelLingua" name="lingue" onChange={async (e) => setLingua(lingue[e.target.value])}> 
                            {lingue.map((item, index) => (
                                <OptionComponent key={index} index={index} nome={item.nome}/>
                            ))} 
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select value={getOptionIndex(tipi, tipo)} id="SelTipo" name="tipi" onChange={async (e) => setTipo(tipi[e.target.value])}> 
                            {tipi.map((item, index) => (
                                <OptionComponent key={index} index={index} nome={item.nome}/>
                            ))} 
                        </Form.Select>
                    </Col>
                </Row>
            </Form.Group>
            <Row>
                    <Col>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type='text' onChange={(e) => setNome(e.target.value)} value={n} maxLength={maxNameLenght}></Form.Control>
                    </Col>
                    <Col>
                        <Form.Label>Oggetto</Form.Label>
                        <Form.Control type='text' onChange={(e) => setOggetto(e.target.value)} value={o}></Form.Control>
                    </Col>
            </Row>
            <Row>
                    <Col>
                    <Form.Label>Body HTML</Form.Label>
                    <Form.Control as='textarea' rows={10} placeholder='Aggiungi body HTML' value={body} onChange={(e) => setBody(e.target.value)}/>
                    </Col>
                    <Col>
                    <Form.Label>Body Text</Form.Label>
                    <Form.Control as='textarea' rows={10} placeholder='Aggiungi body text' value={text} onChange={(e) => setText(e.target.value)}/>
                    </Col>
            </Row>
            <br />
            <div className="d-grid gap-2">
            <Col><Button type='submit' variant="success">Salva</Button></Col>  <Col></Col> <Col></Col>
            </div>
            <PreviewComponent corpo={body}/>
        </Form>}

        {show &&  <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Insert a body</Alert.Heading>
        <p>
         You've to insert a body to save a new template
        </p>
      </Alert>}
    </Container>
    )
}

export default TemplateModifyView
