import { toast } from "react-toastify"

export class toastUtility {

    static _options   = {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    static success(message)  {
        toast.success(message, this._options);
    }

    static error(message)  {
        toast.error(message,  this._options);
    }

}