import {getError} from '../Utils/ErrorUtil'
import {headers, headersJSON, address} from '../Utils/FetchUtil'
import { toastUtility } from '../Utils/toast-utilities'

class ImageService {
  
  async fetchData(currentDirPath) {

    const res = await fetch(`${address}immagini/?currentDirPath=${currentDirPath}`,{
       headers,
    })
    const status = await res.status
    if (status === 200 || status === 204)  {
      const data = await res.json()
      return data
    }
    else toastUtility.error(getError(status))
    return []

  }
  
  async fetchFolders() {

    const res = await fetch(`${address}immagini/GetFolders`,{
       headers,
    })
    const status = await res.status
    if (status === 200 || status === 204)  {
      const data = await res.json()
      return data
    }
    else toastUtility.error(getError(status))
    return []

  }

  async addFolder(folderName, currentDirPath) {
    const res = await fetch(`${address}immagini/CreateFolder/?folderName=${folderName}` , {
        method: 'POST',
        headers: headersJSON,
        body: JSON.stringify({"currentDirPath": currentDirPath})
    })
    if ( res.status === 200 ||  res.status === 204)  {
      toastUtility.success("Cartella aggiunta correttamente");
    }
    else {
      toastUtility.error("Errore nella creazione della cartella");
    }
    return res.status
  }

  async deleteFolder(currentDirPath) {
    const res = await fetch(`${address}immagini/DeleteFolder/?currentDirPath=${currentDirPath}` , {
        method: 'POST',
        headers: headers
    })
    if ( res.status === 200 ||  res.status === 204)  {
      toastUtility.success("Cartella eliminata correttamente");
    }
    else {
      toastUtility.error("Errore nell'eliminazione della cartella");
    }
    return res.status
  }

  async addElement(files, folderPath) {
    const formData =  new FormData();
    for(var i = 0; i< files.length; i ++){ 
        formData.append(`formFile`, files[i]);
    }
    const res = await fetch(`${address}immagini?folderPath=${folderPath}` , {
        method: 'POST',
        headers: headers,
        body: formData,
    })
    if ( res.status === 200 ||  res.status === 204)  {
      toastUtility.success("Immagini aggiunte correttamente");
    }
    else {
      toastUtility.error("Errore nel salvataggio delle immagini");
    }
    return res.status
  }

  async deleteElement(id) {
        const res = await fetch(`${address}immagini/${id}`, {
            method: 'DELETE',
            headers
        })
        if ( res.status === 200 ||  res.status === 204)  {
          toastUtility.success("Immagine eliminata correttamente");
        }
        else {
          toastUtility.error("Errore nell'eliminazione dell'immagine");
        }
        return res.status

  }
}


export {ImageService}
