import { headersJSON, addressLogin } from "../Utils/FetchUtil"

const login = async (username, password) => {
    const user = {
        username: username,
        password: password
    }
    const res = await fetch(`${addressLogin}` , {
        method: 'POST',
        headers: headersJSON,
        body: JSON.stringify(user)
    })
    await res.json
    const status = await res.status
    return (status === 200)
}

export { login }