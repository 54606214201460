import {headers, headersJSON, address} from '../Utils/FetchUtil'
import { toastUtility } from '../Utils/toast-utilities';
class TemplateService {
    constructor() {
    }
     
    async deleteTemplate(id) {
        const res = await fetch(`${address}templates/${id}`, {
          method: 'DELETE',
          headers
        })
        if ( res.status === 200 ||  res.status === 204)  {
            toastUtility.success("Template eliminato correttamente");
          }
          else {
            toastUtility.error("Errore nell'eliminazione del template");
          }
        return res.status
    }

    async fetch(id) 
    {
        const res = await fetch(`${address}templates/grouped/${id}`, {
            headers
        })
        return await res
    }

    async cloneTemplate(template) {
        template.id = -1
        template.nome = template.nome + " Copia"
        const res = await fetch(`${address}templates` , {
            method: 'POST',
            headers: headersJSON,
            body: JSON.stringify(template)
        })
        if ( res.status === 200 ||  res.status === 204)  {
            toastUtility.success("Template clonato correttamente");
          }
          else {
            toastUtility.error("Errore nel clonare il template");
          }
        return res.status
    }


    async modTemplate(template) {
        const res = await fetch(`${address}templates` , {
            method: 'PUT',
            headers: headersJSON,
            body: JSON.stringify(template)
        })
        if ( res.status === 200 ||  res.status === 204)  {
            toastUtility.success("Template modificato correttamente");
          }
          else {
            toastUtility.error("Errore nella modifica del template");
          }
        return res.status       
    }

    async addTemplate(template) {
        const res = await fetch(`${address}templates` , {
            method: 'POST',
            headers: headersJSON,
            body: JSON.stringify(template)
        })
        if ( res.status === 200 ||  res.status === 204)  {
            toastUtility.success("Template salvato correttamente");
          }
          else {
            toastUtility.error("Errore nel salvataggio del template");
          }
        return res.status
    }
}

export {TemplateService}