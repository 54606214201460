import React, { useState } from 'react'
import { Container,Row,Col, Button, Alert, Form } from 'react-bootstrap'
import { getError } from '../Utils/ErrorUtil'
const AddElementoComponent = ({onAdd, tipo}) => {
    const [nome, setNome] = useState('') 
    const add = async (e) => {
        e.preventDefault()
        if(nome === '')
        {
             alert('Inserisci un nome'); 
             return;
        }
        await onAdd(nome)        

        setNome('')
    }

   

    return (
        <Container>
                <Form>
                    <Form.Group className="mb-3">
                        <Row>
                                <Form.Control type='text' onChange={(e) => setNome(e.target.value)} value={nome} placeholder='Inserisci nome'></Form.Control>
                                
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Button type='submit' variant="success" onClick={add}>Aggiungi {tipo}</Button>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Form.Group>
                </Form> 

        </Container>
    )
}

export default AddElementoComponent
