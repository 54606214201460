import React from 'react'
import ReactTooltip from 'react-tooltip';
import {Container, Button, Row, Col, CloseButton, Form, Tooltip, OverlayTrigger  }  from 'react-bootstrap';
import { FaCopy, FaEdit, FaTrash } from 'react-icons/fa';

const GridRowComponent = ({id, corpo, onDelete, onEdit, template, edit , onClone, clone, copy, onCopy}) => {
    let albergoId = template.albergo !== undefined ? template.albergo.id : "";
    return (
        
        <>
            <td>{corpo}</td>
            {edit &&<td md={2}> <Button variant="link" onClick={(e)=> {onEdit(template)}}><FaEdit/></Button> </td>}
            {clone &&<td md={2}> <Button variant="link" onClick={(e)=> {onClone(template)}}><FaCopy/></Button> </td>}
            <td> <Button  variant="link" onClick={(e)=> {onDelete(template)}} ><FaTrash/></Button></td>
            {copy && <td md={2}> <Button variant="link" onClick={(e)=> {onCopy(template.src)}}><FaCopy/></Button> </td>}
            
            {copy && 
                <td><img src={template.src+"?t="+ new Date().getTime()} height={100}></img></td>
            }
        </>        
    )
}

GridRowComponent.defaultProps = {
    clone: false,
    edit: false,
    copy: false,
    onCopy : () => {}
}

export default GridRowComponent