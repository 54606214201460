import React from 'react'
import { useState, useEffect } from 'react'
import { ElementService } from '../Services/ElementService'
import {maxNameLenght} from '../Utils/DataUtils'

import { getError } from '../Utils/ErrorUtil'

import { GridRowComponent, OptionComponent, PreviewComponent } from '../components'
import { TemplateAddView, TemplateModifyView } from '.'
import { TemplateService} from '../Services/TemplateService'

import {Container, Button, Row, Col, CloseButton, Form, Table, Spinner,OverlayTrigger, Tooltip, Pagination}  from 'react-bootstrap';

const TemplateView = () => {

    const [templatesToBeShown, setTemplate]  = useState([])
    const [idAlbergo, setIdAlbergo] = useState(-1)
    const [nomeAlbergo, setNomeAlbergo] = useState('')
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [template, setModTemplate] = useState(null)
    const [alberghi, setAlberghi] = useState(null)
    const [lingue, setLingue] = useState(null)
    const [tipi, setTipi] = useState(null)
    const [pageItem, setPageItem] = useState([])
    const [loaded, setLoaded] = useState([])
    const [active, setActive] = useState(1)
    const [dataReceived, setDataReceived] = useState(0)
    var l = 0

    const paginationSize = 10
    const elementService = new ElementService()
    const templateService = new TemplateService()

    const getData = async () => {
        elementService.datatype = 'alberghi'
        const a = await elementService.fetchData()
        elementService.datatype = 'tipi'
        const t = await elementService.fetchData()
        elementService.datatype = 'lingue'
        const l = await elementService.fetchData()
        let sortedA = [...a].sort((x,y) => x.id - y.id)
        let sortedT = [...t].sort((x,y) => x.id - y.id)
        let sortedL = [...l].sort((x,y) => x.id - y.id)
        setAlberghi(sortedA)
        setTipi(sortedT)
        setLingue(sortedL)
    }

    useEffect(() => {
        getData()     
    }, [])

    useEffect(() => {
        check()     
    }, [alberghi, lingue, tipi])
      
// 0 = non ho ancora caricato i dati, 2 = ho caricato i dati e ci sno valori in tutti, 1 = ho caricato i dati ma almeno uno è una lista vuota
      const check = () => {
        setDataReceived(0);
            if ( tipi !== null && alberghi !== null && lingue !== null ) {
                if(alberghi.length > 0 && lingue.length > 0 && tipi.length > 0) {
                    if (idAlbergo === -1) 
                    {
                        setNomeAlbergo(alberghi[0].nome)
                        updateTemplates(alberghi[0].id, true)
                        setIdAlbergo(alberghi[0].id)
                    }
                    setDataReceived(2);
                }else{
                    setDataReceived(1);
                }
            }
      }
    
    const deleteTemplate = async (gridElem) => {
        if(!window.confirm("Sei sicuro di eliminare questo template?")) return;
        const status = await templateService.deleteTemplate(gridElem.id)
        if(status === 200 || status === 204) 
        {
            updateTemplates(gridElem.albergo.id, true)
        }
        else {
            alert(getError(status))
        }
    }
    
    
    const editTemplate = (template) => {
        setShowEdit(true)
        setModTemplate(template)
    }

    const changePage =  (n) => {
        var x = document.getElementsByClassName("pag")
        x[0].childNodes.forEach((i, index) =>{ if(index === n-1) i.className='page-item active'; else i.className='page-item';})
        setActive(n)
    }


    const fetchTemplates = async (id) => {
        const res = await templateService.fetch(id)
        const status = await res.status
        if (status === 200 ||status === 204 ) {
            const data = await res.json()
            return data
        }
        return []
    }

    const updateTemplates = async (id, load) => {
        var fetchedTemplates = []
        const searchBar = document.getElementById("searchTemplate")
        var searchedName = ''
        if(searchBar !== null) searchedName = searchBar.value
        if(load) fetchedTemplates = await fetchTemplates(id);
        else fetchTemplates = loaded
        setLoaded(fetchedTemplates)
        fetchedTemplates = fetchedTemplates.filter(t => t.nome.startsWith(searchedName))
        l = fetchedTemplates.length
        var nPage = Math.floor(l/paginationSize)
        if(l % paginationSize > 0) nPage += 1
        let items = []
        for(let y = 1; y <= nPage; y++){
            items.push(
                <Pagination.Item key={y}   active={y === active} onClick={(e) => changePage(y)}>
                {y}
                </Pagination.Item>,
            );
        }

        let itemsToShow = []

        for(let n = 0; n < nPage; n++){
            let itemsPerPage = []
            for(let i = n*paginationSize; i < (n*paginationSize + paginationSize); i++){
                if(i >= fetchedTemplates.length ){break;}
                const item = fetchedTemplates[i]
                itemsPerPage.push( 
                            <tr key={i}><GridRowComponent key={i} id={item.id} corpo={item.nome} onDelete={deleteTemplate} onEdit={editTemplate} template={item} edit={true} clone={true} onClone={onClone}/></tr>

                    ) 
                    
            }
            itemsToShow.push(itemsPerPage)
        }
           
        setTemplate(itemsToShow)
        setPageItem(items)
    }

    const showTemplateGrid = async (e) => {
        let albergo = alberghi[e.target.value];
        setIdAlbergo(albergo.id)
        setActive(1)
        setNomeAlbergo(albergo.nome)
        updateTemplates(albergo.id, true);        
    }
    const show = (id) => {
        setShowAdd(!showAdd)
        updateTemplates(id, true)
    }

    const back = (id) => {
        setShowEdit(false)
        updateTemplates(id, true)
    }

    const onClone = async (template) => {
        const status = await templateService.cloneTemplate(template)
        if (status === 200 || status === 204) {
            updateTemplates(template.albergo.id, true)
        }
        else  alert(getError(status))
    }

    const search = (nome) => {
        const fetchedTemplates = loaded.filter(e => e.nome.toLowerCase().includes(nome.toLowerCase()) );
        l = fetchedTemplates.length

            var nPage = Math.floor(l/paginationSize)
            if(l % paginationSize > 0) nPage += 1
            let items = []
            for(let n = 1; n <= nPage; n++){
                items.push(
                    <Pagination.Item key={n}   active={n === active} onClick={(e) => changePage(n)}>
                    {n}
                  </Pagination.Item>,
                );
            }

            let itemsToShow = []

            for(let n = 0; n < nPage; n++){
                let itemsPerPage = []
                for(let i = n*paginationSize; i < (n*paginationSize + paginationSize); i++){
                    if(i >= fetchedTemplates.length) break;
                    const template = fetchedTemplates[i]
                    itemsPerPage.push(  
                                <tr key={i}><GridRowComponent key={i} id={template.id} corpo={template.nome} onDelete={deleteTemplate} onEdit={editTemplate} template={template} edit={true} clone={true} onClone={onClone}/></tr>

                        ) 
                }
                itemsToShow.push(itemsPerPage)
            }
            setTemplate(itemsToShow)
            setPageItem(items)
    } 


    return (
        <Container>
            {dataReceived  === 0 &&  <center><Spinner animation="border" /></center>}
            {dataReceived === 1 && <p>Dati non presenti</p>}
            <Row> 
            {dataReceived === 2  &&
                    <Col  xs={6} md={4} hidden={showAdd || showEdit}> 
                        <Form.Select id="SelAlbergo" name="alberghi" onChange={showTemplateGrid}> 
                            {alberghi.map((albergo, index) => (
                                <OptionComponent key={index} index={index} nome={albergo.nome}/>
                            ))} 
                        </Form.Select>
                        <br/>  
                        <Button  variant="primary" onClick={() => setShowAdd(true)}>Aggiungi Template</Button>
                    </Col> 
            }
            { dataReceived === 2 && !showAdd && !showEdit &&
                    (<Col xs={12} md={8} >
                        <h3>Templates : {nomeAlbergo}</h3>dataReceived
                            <Form.Label>Ricerca per nome</Form.Label>
                            <Form.Control type='text' id="searchTemplate" onChange={(e) => search(e.target.value)} placeholder='Inserisci nome' maxLength={maxNameLenght}></Form.Control>
                            <br/>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Modifica</th>
                                        <th>Clona</th>
                                        <th>Elimina</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templatesToBeShown[active-1]}
                                </tbody>
                            </Table>
                            <Pagination className='pag'>{pageItem}</Pagination>
                    </Col>)
             }
             {dataReceived == 2 && showAdd && <Container> <CloseButton  onClick={() => show(idAlbergo)} />  <TemplateAddView  alberghi={alberghi} tipi={tipi} lingue={lingue} templateService={templateService} back={() => show(idAlbergo)}/></Container> }
            {showEdit && 
                <Container>   
                    <CloseButton  onClick={() => back(idAlbergo)} /> 
                    <TemplateModifyView 
                        alberghi={alberghi} 
                        tipi={tipi} 
                        lingue={lingue} 
                        template={template} 
                        templateService={templateService} 
                        back={() => back(idAlbergo)}/> 
                </Container>}
            </Row>
        </Container>
    )
}

export default TemplateView