import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Container,Row,Col, CloseButton, Table, Spinner} from 'react-bootstrap'
import { GridRowComponent, AddElementoComponent } from  '../components'
import { ModElementView } from './'
import { getError } from '../Utils/ErrorUtil'
import {ElementService} from '../Services/ElementService'

const ElementiView = ({tipo, edit}) => {

    const [items, setItems] = useState(null)
    const [showEdit, setShowEdit] = useState(false)
    const [elementMod, setElementMod] = useState(null)
    var title = 'Tipi'
    var aggiungi = 'tipo'
    const elementService = new ElementService(tipo)

    const getData = async () =>
    {
        const i = await elementService.fetchData()
        setItems(i)
    }

    useEffect(() => {
        getData()
    }, [])

    if(tipo === 'alberghi') {title = 'Alberghi'; aggiungi = 'albergo';}
    else if(tipo === 'lingue') {title = 'Lingue'; aggiungi= 'lingua';}

    const onDelete = async (gridElem) => {
        if(!window.confirm("Sei sicuro di eliminare questo elemento?")) return;
            const status = await elementService.deleteElement(gridElem.id)
            if (status === 200 || status === 204) setItems(items.filter(i => i.id !== gridElem.id)) 
            else alert(getError(status))
    }
    const onAdd = async (nome) => {
        const response = await elementService.addElement(nome)
        if (response === 200 || response === 204) getData()

        return response
    }

    const mod = async (nome) => {
        const response = await elementService.modElement(elementMod.id, nome)

        if(response === 200) {
            getData()
        }
        return response
    }

    const clickEdit = (el)=>{ 
        setShowEdit(!showEdit);
        setElementMod(el); 
    }
    // 0 = non ho ancora caricato i dati, 2 = ho caricato i dati e ci sono valori, 1 = ho caricato i dati ma ho una lista vuota

    const check = () => { 
        if(items !== null){
            if(items.length > 0) return 2
            return 1
        }
        return  0
    }

  

    return (
        <Container>
            {check() !== 0 && ( showEdit  ? (
                <Container> 
                    <Row>
                        <CloseButton  onClick={() => {setShowEdit(false);}} /> 
                    </Row>
                    <Row>
                        {<ModElementView element={elementMod} mod={mod} setShowEdit={setShowEdit}/> }
                    </Row> 
                </Container>
            ) : (
                <Row>
                <Col xs={6} md={4}>
                    <AddElementoComponent  onAdd={onAdd} tipo={aggiungi} />
                </Col>
                <Col xs={12} md={8}>
                    <h3>{title}</h3>
                    <Table striped bordered hover> 
                        <thead>
                            <tr>
                                <th>Nome</th>
                                {edit && <th>Modifica</th>}
                                <th>Elimina</th>
                            </tr>
                        </thead>
                        <tbody>
                        {   check() === 2 &&
                            items.map((el) => 
                            <tr key={el.id}>
                                <GridRowComponent  linguath={false} key={el.id} id={el.id} corpo={el.nome} template={el} onDelete={onDelete} onEdit={clickEdit}  edit={edit}/> 
                            </tr> )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row> ))
            }
            {check() === 0 && <center><Spinner animation="border" /></center>}
            
        </Container>
    )
}

export default ElementiView
