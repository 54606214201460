import React from 'react'
import { useState } from 'react'
import { Container,Row,Col, Button, Alert, Form } from 'react-bootstrap'
import { getError } from '../Utils/ErrorUtil'

const ModElementView = ({ element, mod, setShowEdit}) => {

    const [Value, setValue] = useState(element.nome)
    const [show, setShow] = useState(false);
    const [correct, setCorrect] = useState(false);
    const [error, setError] = useState(false);
    const onSubmit = async (e) => {
        e.preventDefault()
        if(!Value || Value === '') {
            setShow(true)
            return
        }
        await mod(Value);
        setShowEdit(false);
        
    }

    return (
        <Container fluid>
            {  !show && 
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type='text'  value={Value} onChange={(e) => setValue(e.target.value)}/>
                </Form.Group>
                <div className="d-grid gap-2">
                <Col><Button type='submit' variant="success">Salva</Button></Col>  <Col></Col> <Col></Col>
                </div>
        </Form>}

        {show &&  <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Insert a body</Alert.Heading>
        <p>
         You've to insert a body to save a new template
        </p>
      </Alert>}
    </Container>
    )
}

export default ModElementView
