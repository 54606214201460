import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Container,Row,Col, CloseButton, Table, Spinner, ToastBody} from 'react-bootstrap'
import { GridRowComponent, AddImageComponent } from  '../components'
import { ModElementView } from './'
import { getError } from '../Utils/ErrorUtil'
import {ElementService} from '../Services/ElementService'
import { ImageService } from '../Services/ImageService'
import ImagesFolders from '../components/ImagesFoldersComponent'
import {FolderNode} from '../models/ImageFileSystemNode.js'

function ImagesView() {

  const tipo = 'immagini'
  const [items, setItems] = useState(null)
  const [showEdit, setShowEdit] = useState(false)
  const [elementMod, setElementMod] = useState(null)
  const [currentDirPath, setCurrentDirPath] = useState("/");

  const [imageFoldersTree, setImageFoldersTree] = useState();

  var title = 'Immagini'
  const imageService = new ImageService()

  const getData = async () =>
  {
     const i = await imageService.fetchData(currentDirPath)
      setItems(i)
  }

  const getAllFolders = async () => 
  {
    const res = await imageService.fetchFolders();
    generateFoldersTree(res);
  }

  const generateFoldersTree = (paths) => {
    var tree = new FolderNode('Root', null);

    var actualNode = tree;

    paths.map((path) => {
      let traversePath = path.split("/").filter(el => el);
      traversePath.forEach(folder => {
        let existingSubfolder = actualNode.subfolders.filter(subfolder => subfolder.dirName == folder);
        if(existingSubfolder.length > 0){
          actualNode = existingSubfolder[0];
        } else{
          actualNode.subfolders.push(new FolderNode(folder, actualNode));
        }
      });

      actualNode = tree;
    });
    setImageFoldersTree(tree);
  }

  useEffect(() => {
    getData();
  }, [currentDirPath])

  useEffect(() => {
      getAllFolders();
  }, [])

  const onDelete = async (gridElem) => {
    if(!window.confirm("Sei sicuro di eliminare questa immagine?")) return;
          const status = await imageService.deleteElement(gridElem.id)
          if (status === 200 || status === 204) setItems(items.filter(i => i.id !== gridElem.id)) 
          else alert(getError(status))
  }
  const onAdd = async (images) => {
      const response = await imageService.addElement(images,currentDirPath)
      if (response === 200 || response === 204) getData()
      return response
  }

  // 0 = non ho ancora caricato i dati, 2 = ho caricato i dati e ci sono valori, 1 = ho caricato i dati ma ho una lista vuota

  const check = () => { 
      if(items !== null && imageFoldersTree !== null && imageFoldersTree !== undefined) {
          if(items.length > 0) return 2
          return 1
      }
      return  0
  }
  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
  } 

  return (
    <Container>
        {check() !== 0 && 
          <Row>
           <Col xs={6} md={4}>
             <AddImageComponent addImages={onAdd}/>
           </Col>
           <Col xs={12} md={8}>
               <h3>{title}</h3>
               <ImagesFolders tree={imageFoldersTree} currentDirPath={currentDirPath} setCurrentDirPath={setCurrentDirPath} setItems={setItems}/>
               <Table striped bordered hover> 
                   <thead>
                     <tr>
                        <th>Nome</th>
                        <th>Elimina</th>
                        <th>Copia link</th>
                        <th>Preview</th>
                      </tr>
                   </thead>
                   <tbody>
                   {   check() === 2 &&
                       items.map((el) => 
                       
                            <tr key={el.id}>
                                <GridRowComponent  linguath={false} key={el.id} id={el.id} corpo={el.nome} template={el} onDelete={onDelete} onEdit={() => {}} onCopy={onCopy} edit={false} copy={true}/>
                            </tr> 
                       )
                   }
                   </tbody>
               </Table>
           </Col>
         </Row> 
         }
         {check() === 0 && <center><Spinner animation="border" /></center>}
         
     </Container>
  )
}

export default ImagesView