import React from 'react';
import FrameComponent from 'react-frame-component';
const PreviewComponent = ({corpo}) => {
  return (
      <div> <h3>Preview HTML</h3>
        <div style={{
                'padding': '10px',
                'border' : '1px solid #ddd', 
                'marginBottom' : '20px', 
                'WebkitBorderRadius': '4px',
                'MozBorderRadius': '4px',
                'borderRadius':'4px'
            }}>
            <FrameComponent width='100%' height='900vh'>
            <div dangerouslySetInnerHTML={{__html: corpo}}>
                </div>
            </FrameComponent>
        </div>
    </div>

  )
   
};

export default PreviewComponent;
