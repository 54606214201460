import React, { useState } from 'react';
import { Container, Form, Alert, Col, Button, Row, InputGroup, FormControl } from 'react-bootstrap';
import { MailCareView } from '.';
import { login } from '../Services/LoginService';






const LoginView = () => {

    const [logged, setLogged] = useState(false)
    const [username, setUsername] = useState ('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const onSubmit = async () =>
    {
        const res = await login(username, password)
        if (res) setLogged(true);
        else setError(true);
    }

  return (
        <Container>
            <br />
            { !logged ?
                <Row>
                    <Col xs={3} md={3} lg={3} className="my-1"></Col>
                    <Col xs={6} md={6} lg={6} className='shadow'>
                        <Row>
                            <Col xs={3} md={3} lg={3} className="my-1"></Col>
                            <Col xs={6} md={6} lg={6} className="my-1">
                                <br />
                                <Row className="align-items-center">
                                    <center><h3>LogIn</h3></center>
                                </Row>
                                <br />
                                <Row className="align-items-center">
                                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                        Username
                                    </Form.Label>
                                    <Form.Control id="inlineFormInputName" type='text'  placeholder='username'  onChange={(e) => setUsername(e.target.value)} />
                                </Row>
                                <br />
                                <Row  className="align-items-center">
                                    <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                        Password
                                    </Form.Label>
                                    <FormControl id="inlineFormInputGroupUsername" type='password'  placeholder='password'  onChange={(e) => setPassword(e.target.value)} />
                                </Row>
                                <br />
                                <Row  className="align-items-center">
                                    <Button type='submit' variant="primary" onClick={onSubmit}>Accedi</Button>
                                </Row>
                                <br />
                                {error &&
                                    <Row className="align-items-center"> 
                                        <Alert variant="danger" onClick={() => setError(false)}dismissible>
                                            <Alert.Heading>Login Error</Alert.Heading>
                                            <p>Username e/o password errati </p>
                                        </Alert>
                                    </Row> 
                                }
                                <br />
                            </Col>
                            <Col xs={3} md={3} lg={3} className="my-1"></Col>
                        </Row>
                    </Col> 
                    <Col xs={3} md={3} lg={3} className="my-1"></Col>
                </Row>
            : <MailCareView />}


        </Container>
    );
  }
export default LoginView;
