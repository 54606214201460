import React from 'react'
import {ButtonGroup, Button } from 'react-bootstrap'
const MenuComponent = (props) => {
    return (
        <ButtonGroup aria-label="Basic example">
             <Button   variant="dark" size='lg' onClick={props.showTemplate}>Template</Button >
             <Button  variant="dark"  size='lg' onClick={props.showTipi}>Tipi</Button >
             <Button  variant="dark" size='lg' onClick={props.showLingue}>Lingue</Button >
             <Button variant="dark" size='lg' onClick={props.showAlberghi}>Alberghi</Button >
             <Button variant="dark" size='lg' onClick={props.showImmagini}>Immagini</Button >
        </ButtonGroup >
    )
}

export default MenuComponent