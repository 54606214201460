import {getError} from '../Utils/ErrorUtil'
import {headers, headersJSON, address} from '../Utils/FetchUtil'
import { toastUtility } from '../Utils/toast-utilities';

class ElementService {
  constructor(datatype) {
    this.datatype = datatype;
  }

  async fetchData() {

    const res = await fetch(`${address}${this.datatype}`,{
       headers,
    })
    const status =  res.status
    if (status === 200 || status === 204)  {
      const data = await res.json()
      return data
    }
    else toastUtility.error(getError(status))
    return []

  }

  async modElement(id, nome) {
    const element = {
      id: id,
      nome: nome
    }
    const res = await fetch(`${address}${this.datatype}` , {
      method: 'PUT',
      headers: headersJSON,
      body: JSON.stringify(element)
    })
    if ( res.status === 200 ||  res.status === 204)  {
      toastUtility.success("Elemento modificato correttamente");
    }
    else {
      toastUtility.error("Errore nella modifica dell'elemento");
    }
    return res.status
  }

  async addElement(nome) {
    const element = {
        nome: nome
    }
    const res = await fetch(`${address}${this.datatype}` , {
        method: 'POST',
        headers: headersJSON,
        body: JSON.stringify(element)
    })
    if ( res.status === 200 ||  res.status === 204)  {
      toastUtility.success("Elemento aggiunto correttamente");
    }
    else {
      toastUtility.error("Errore nel salvataggio dell'elemento");
    }
    return  res.status
  }

  async deleteElement(id) {

        const res = await fetch(`${address}${this.datatype}/${id}`, {
            method: 'DELETE',
            headers
        })
        if ( res.status === 200 ||  res.status === 204)  {
          toastUtility.success("Elemento eliminato correttamente");
        }
        else {
          toastUtility.error("Errore nell'eliminazione dell'elemento");
        }
        return res.status
  }
}


export {ElementService}
